body {
  margin: 0;
  line-height: 0.8!important;
}
*{
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}


html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
}

.top-section {
  display:flex;
  flex-direction: row;
  height: 80px;
  border-bottom: 1px solid #efefef;
}

.bottom-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.nav {
  display: flex;
  flex-direction: row;
}

.main {
  flex-grow: 1;
}

.text-seperate::before{
  content: "•";
  margin: 0 4px;
}
.shared-badge{
  font-size: 0.75rem;
  padding:5px;
  border-radius: 2px;
  background-color: rgb(237, 247, 237) ;
  color: rgb(30, 70, 32);
}
.claimed-badge {
  margin-left: 5px;
  font-size: 0.75rem;
  padding:5px;
  border-radius: 2px;
  background-color: rgb(229, 246, 253);
  color: rgb(1, 67, 97);
}